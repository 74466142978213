<script setup lang="ts">
  import {SkeletonBlock} from '@skeleton-elements/vue';
  import useCpBem from '~/composables/useCpBem';
  import '@skeleton-elements/vue/skeleton-elements.css';

  withDefaults(defineProps<{
    kind: 'article-card-vertical' | 'brands' | 'card-catalog-simple' | 'banner' | 'button' | 'card-catalog' | 'section' | 'categories' | 'order' | 'recent-order' | 'title' |
    'card-navigate-to' | 'simple-block' | 'article-card-vertical-rai' | 'gallery' | 'my-reviews' | 'product-details-status-card'
    effect: 'fade' | 'pulse' | 'none',
    withoutBorder?: boolean,
    // These props are string cause percentages are valid
    height?: string
    width?: string
    applyAspectRatio?: boolean
    af?: boolean
  }>(), {
    withoutBorder: false,
    effect: 'none',
    af: false,
  });

  const {bm, e, em} = useCpBem('cp-skeleton');

</script>

<template>
    <div :class="bm({ withBorder: !withoutBorder, af })">
        <div v-if="kind === 'article-card-vertical'" :class="e('article-card-vertical')">
            <div :class="e('pill-container')">
                <skeleton-block :effect="effect" width="47px" height="20px" :class="e('pill')" />
            </div>
            <skeleton-block :effect="effect" width="140px" height="140px" />
            <div :class="e('elements')">
                <skeleton-block :effect="effect" width="80px" />
                <skeleton-block :effect="effect" width="100%" height="40px" />
            </div>
            <div :class="em('elements', { 'right': true })">
                <skeleton-block :effect="effect" width="47px" />
                <skeleton-block :effect="effect" width="65px" />
            </div>
        </div>
        <div v-if="kind === 'article-card-vertical-rai'" :class="e('article-card-vertical-rai')">
            <div :class="e('pill-container')">
                <skeleton-block :effect="effect" width="47px" height="20px" :class="e('pill')" />
            </div>
            <div :class="e('center-content')">
                <skeleton-block :effect="effect" width="100px" height="100px" />
            </div>
            <div :class="e('elements')">
                <skeleton-block :effect="effect" width="80px" />
                <skeleton-block :effect="effect" width="120px" height="30px" />
            </div>
            <div :class="em('elements', { 'right': true })">
                <skeleton-block :effect="effect" width="47px" />
                <skeleton-block :effect="effect" width="65px" />
            </div>
        </div>
        <div v-if="kind === 'card-catalog-simple'" :class="e('card-catalog-simple')">
            <div>
                <skeleton-block :effect="effect" width="120px" height="120px" />
            </div>
            <div :class="e('info-container')">
                <div :class="e('elements')">
                    <skeleton-block :effect="effect" width="100%" />
                    <skeleton-block :effect="effect" width="100%" />
                </div>
                <div :class="em('elements', { 'right': true })">
                    <skeleton-block :effect="effect" width="71px" />
                </div>
            </div>
        </div>
        <skeleton-block v-if="kind === 'banner'"
            :effect="effect"
            width="100%"
            height="100%"
            :class="applyAspectRatio ? e('banner') : ''"
        />
        <div v-if="kind === 'button'">
            <skeleton-block :effect="effect" width="100%" height="48px" />
        </div>
        <div v-if="kind === 'card-catalog'" :class="em('card-catalog', {af})">
            <div>
                <skeleton-block :effect="effect" width="120px" height="120px" :class="em('skeleton-border', {af})" />
            </div>
            <div :class="e('description')">
                <div>
                    <skeleton-block :effect="effect" height="16px" :class="em('skeleton-border-top', {af})" />
                    <skeleton-block :effect="effect" height="16px" width="55%" :class="em('skeleton-border-bottom', {af})" />
                </div>
                <skeleton-block :effect="effect" height="20px" width="40%" :class="em('skeleton-border', {af})" />
                <div>
                    <skeleton-block :effect="effect" height="16px" :class="em('skeleton-border-top', {af})" />
                    <skeleton-block :effect="effect" height="16px" width="40%" :class="e('skeleton-border-bottom', {af})" />
                </div>
                <div :class="e('price')">
                    <skeleton-block :effect="effect" height="24px" :class="em('skeleton-border', {af})" />
                </div>
            </div>
        </div>
        <div v-if="kind === 'section'" :class="e('section')">
            <skeleton-block :effect="effect" width="32px" height="32px" />
            <skeleton-block :effect="effect" width="100%" />
        </div>
        <div v-if="kind === 'categories'" :class="e('categories')">
            <skeleton-block :effect="effect" width="90%" height="40px" />
            <skeleton-block :effect="effect" width="100%" />
        </div>
        <div v-if="kind === 'order'">
            <div :class="e('order-section')">
                <div :class="e('elements')">
                    <skeleton-block :effect="effect" width="100%" />
                    <skeleton-block :effect="effect" width="100%" />
                </div>
                <div>
                    <skeleton-block :effect="effect" width="20px" height="20px" />
                </div>
            </div>
            <div :class="e('order-section')">
                <skeleton-block :effect="effect" width="80px" height="80px" :class="e('skeleton-border')" />
                <div :class="e('elements')">
                    <skeleton-block :effect="effect" width="100%" />
                    <skeleton-block :effect="effect" width="100%" />
                </div>
            </div>
            <div :class="e('skeleton-padding')">
                <skeleton-block :effect="effect" width="100%" />
            </div>
        </div>
        <div v-if="kind === 'recent-order'">
            <div :class="e('card-container')">
                <div>
                    <skeleton-block :effect="effect" height="16px" width="65%" :class="e('skeleton-border-top')" />
                    <skeleton-block :effect="effect" height="16px" width="75%" :class="e('skeleton-border-bottom')" />
                </div>
                <div :class="e('items-center')">
                    <skeleton-block :effect="effect" width="48px" height="48px" :class="e('skeleton-border')" />
                    <skeleton-block :effect="effect" width="48px" height="48px" :class="e('skeleton-border')" />
                    <skeleton-block :effect="effect" width="48px" height="48px" :class="e('skeleton-border')" />
                </div>
                <div>
                    <skeleton-block :effect="effect" width="100%" height="48px" :class="e('skeleton-border')" />
                </div>
            </div>
        </div>
        <div v-if="kind === 'title'" :class="e('skeleton-title-container')">
            <skeleton-block effect="fade" width="55%" height="24px" :class="e('skeleton-title')" />
        </div>
        <div v-if="kind === 'card-navigate-to'" :class="e('card-navigate-to')">
            <skeleton-block effect="fade" width="80px" height="32px" :class="e('skeleton-title')" />
            <div :class="e('skeleton-row-style')">
                <skeleton-block effect="fade" width="48px" height="16px" :class="e('skeleton-title')" />
                <skeleton-block effect="fade" width="20px" height="16px" :class="e('skeleton-title')" />
            </div>
        </div>
        <div v-if="kind === 'brands'">
            <div :class="e('skeleton-title-container')">
                <skeleton-block effect="fade" width="18px" height="24px" :class="e('skeleton-title')" />
            </div>
            <div style="display: flex; flex-direction: row; gap: 16px; flex-wrap: wrap; justify-content: center; padding: 16px">
                <skeleton-block
                    v-for="i in 24"
                    :key="i"
                    effect="fade"
                    width="calc((100% - 32px) / 3)"
                    height="74px"
                    :class="e('skeleton-title')"
                />
            </div>
        </div>
        <div v-if="kind === 'my-reviews'">
            <div :class="e('skeleton-my-reviews')">
                <div :class="e('skeleton-search')">
                    <skeleton-block effect="fade" height="34px" :class="e('skeleton-border')" />
                    <skeleton-block effect="fade" width="160px" height="16px" :class="e('skeleton-border')" />
                </div>
                <skeleton-block effect="fade" width="80%" height="18px" :class="e('skeleton-border')" />
                <div v-for="n in 3" :key="n" :class="e('border')">
                    <div :class="e('order-section')">
                        <div :class="e('elements')">
                            <skeleton-block :effect="effect" width="100%" />
                            <skeleton-block :effect="effect" width="100%" />
                        </div>
                        <div>
                            <skeleton-block :effect="effect" width="20px" height="20px" />
                        </div>
                    </div>
                    <div :class="e('order-section')">
                        <skeleton-block :effect="effect" width="80px" height="80px" :class="e('skeleton-border')" />
                        <div :class="e('elements')">
                            <skeleton-block :effect="effect" width="100%" />
                            <skeleton-block :effect="effect" width="100%" />
                        </div>
                    </div>
                    <div :class="e('skeleton-padding')">
                        <skeleton-block :effect="effect" width="100%" />
                    </div>
                </div>
            </div>
        </div>
        <skeleton-block v-if="kind === 'simple-block'" :effect="effect" :width="width" :height="height" />
        <div v-if="kind === 'gallery'" style="height: 100%; padding: 16px">
            <div style="display:flex; flex-direction: column; gap: 16px; width: 100%; height: 100%;">
                <div style="display:flex; flex-direction: row; gap: 16px; width: 100%; flex: 1">
                    <skeleton-block :effect="effect" width="100%" height="100%" />
                </div>
                <div style="display:flex; flex-direction: row; gap: 16px; width: 100%">
                    <skeleton-block :effect="effect" width="33%" style="padding-top: 33%;" />
                    <skeleton-block :effect="effect" width="33%" style="padding-top: 33%;" />
                    <skeleton-block :effect="effect" width="33%" style="padding-top: 33%;" />
                </div>
            </div>
        </div>
        <div v-if="kind === 'product-details-status-card'" :class="e('details-status-card')">
            <skeleton-block :effect="effect" width="33%" />
            <skeleton-block :effect="effect" width="70%" />
            <skeleton-block :effect="effect" width="50%" />
            <div :class="e('details-status-card-date')">
                <skeleton-block :effect="effect" width="20%" />
                <skeleton-block :effect="effect" width="20%" />
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
  .cp-skeleton {

    &--with-border {
      border: $cp-border-standard;
      border-radius: $cp-border-radius;
    }

    &__article-card-vertical {
      display: flex;
      flex-direction: column;
      gap: $cp-gutter-small;
      justify-content: center;
      padding: $cp-gutter-large $cp-gutter-small $cp-gutter-small $cp-gutter-small;
      align-items: center;
      position: relative;
      // min-width: 172px;
    }

    &__article-card-vertical-rai {
      display: flex;
      flex-direction: column;
      gap: $cp-gutter-small;
      justify-content: center;
      padding: $cp-gutter-large $cp-gutter-small $cp-gutter-small $cp-gutter-small;
      align-items: center;
      position: relative;
      height: 100%;
    }

    &__skeleton-title-container {
      padding: 16px;
      border-top: $cp-border-standard;
      border-bottom: $cp-border-standard;
    }

    &__skeleton-title {
      border-radius: $cp-gutter-small;
    }

    &__elements {
      display: flex;
      flex-direction: column;
      gap: $cp-gutter-small;
      width: 100%;
      &--right {
        align-items: flex-end;
      }
    }

    &__card-catalog-simple {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $cp-gutter-small;
      gap: $cp-gutter-small;
    }

    &__info-container {
      width: 100%;
      flex-direction: column;
      display: flex;
      gap: $cp-gutter-small;
      height: 120px;
      justify-content: space-between;
    }

    &:deep(.skeleton-block) {
      border-radius: $cp-border-radius;
      --skeleton-color: var(--color-neutral-extra-light);
    }

    &--af:deep(.skeleton-block) {
      --skeleton-color: var(--color-neutral);
    }

    &:deep(.skeleton-avatar) {
      --skeleton-color: var(--color-neutral-extra-light);
    }

    &__card-catalog {
      display: flex;
      align-items: center;
      border: $cp-border-standard;
      border-radius: $cp-border-radius;
      gap: $cp-gutter-xsmall;
      padding: $cp-gutter;

      &--af {
        background-color: var(--color-neutral-dark);
        border-color: var(--color-neutral);
      }
    }

    &__skeleton-border {
      border-radius: $cp-gutter-xsmall;

      &--af {
        --skeleton-color: var(--color-neutral);
      }
    }

    &__skeleton-border-top {
      border-radius: $cp-gutter-xsmall $cp-gutter-xsmall $cp-gutter-xsmall 0;
    }

    &__skeleton-border-bottom {
      border-radius: 0 0 $cp-gutter-xsmall $cp-gutter-xsmall;
    }

    &__description {
      display: flex;
      flex-direction: column;
      gap: $cp-gutter-small;
      width: 100%;
      > * {
        display: flex;
      flex-direction: column;
      gap: $cp-gutter-xsmall;
      }
    }

    &__card-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      > * {
        display: flex;
        flex-direction: column;
        padding: $cp-gutter;
        gap: $cp-gutter-xsmall;
      }
    }

    &__items-center {
      display: flex;
      flex-direction: row;
      align-self: center;
      justify-content: center;
      width: 100%;
      border-top: $cp-border-standard;
      border-bottom: $cp-border-standard;
    }

    &__center-content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &__price {
      align-self: flex-end;
      width: 60%;
    }

    &__pill-container {
      display: flex;
      position: absolute;
      top: 0;
      left: 0;
      margin-top: -1px;
      margin-left: -1px;
    }

    &__pill {
      border-radius: $cp-border-radius 0 !important;
    }

    &__section {
      display: flex;
      gap: $cp-gutter-small;
      align-items: center;
      padding: $cp-gutter-xsmall;
    }

    &__categories {
      display: flex;
      padding: $cp-gutter-small;
      gap: $cp-gutter-small;
      flex-direction: column;
      align-items: center;
    }

    &__order-section {
      display: flex;
      flex-direction: row;
      padding: $cp-gutter;
      gap: $cp-gutter-small;
      border-bottom: $cp-border-standard;
      > * {
        display: flex;
        align-items: center;
      }
    }

    &__skeleton-padding {
      padding: $cp-gutter;
    }

    &__card-navigate-to {
      border: $cp-border-standard;
      border-radius: $cp-gutter-small;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: $cp-gutter;
      justify-content: space-between;
      gap: $cp-gutter-small;
    }

    &__skeleton-row-style {
      display: flex;
      flex-direction: row;
      gap: $cp-gutter-small
    }

    &__banner {
      width: 100%;
      aspect-ratio: 413 / 163;
    }

    &__skeleton-my-reviews {
      display: flex;
      padding: $cp-gutter;
      flex-direction: column;
      gap: $cp-gutter;
    }

    &__skeleton-search {
      display: flex;
      flex-direction: column;
      gap: $cp-gutter-xsmall;
    }

    &__border {
      border: $cp-border-standard;
      border-radius: $cp-border-radius;
    }
}
</style>
